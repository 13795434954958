import React from "react";

class Section extends React.Component {

  render() {
    const attrs = {
      className: 'section'
    }
    if (this.props.id) {
      attrs.id = this.props.id
    }
    if (this.props.className) {
      attrs.className = `${attrs.className} ${this.props.className}`
    }
    return (
      <section style={{ 'backgroundColor': this.props.bgColor }} { ...attrs }>
        { this.props.children }
      </section>
    )
  }
}

export default Section
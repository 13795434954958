import React from "react";
import SEO from "../components/seo";
import Section from "../components/section/section";
import Layout from "../components/layout";
import {graphql, useStaticQuery} from "gatsby";
import {get} from "lodash";
import Img from "gatsby-image/withIEPolyfill";

class ThankYouPage extends React.Component {
  constructor(props) {
    super(props)
    const title = 'Thank You | Therapetic'
    this.seo = {
      title: title,
      description: '',
      seoTitle: title,
    }
  }
  render() {
    const { data } = this.props
    console.log(data)
    const ctaUrl = `${get(data, 'site.siteMetadata.certapetInfo.url.ctaUrl')}`
    return (
      <Layout containerClassNames={`page thank-you`}>
        <SEO { ...this.seo } />
        <div className="page-header-top-placeholder"></div>
        <Section className="thank-you py-2 px-0">
          <div className="row">
            <div className="card col-10 col-md-6 mx-auto mt-5 mb-5 text-center">
                <h2 className="title mt-5">Thank You for your Order!</h2>
                <div className="content">
                 <h3 className="pt-4">You are almost done</h3>
                  <div className="col-2 col-sm-2 col-lg-2 offset-5 text-center py-4 px-auto">
                    <Img fluid={data.icoEmail.childImageSharp.fluid} alt="Email" className="mx-sm-1 mx-5 img-fluid lazyload" />
                  </div>
                  <ul>
                    <li>1. Please check your email</li>
                    <li>2. Click the confirmation link</li>
                    <li>3. Finish your online assessment</li>
                  </ul>
                </div>
              </div>
            </div>
        </Section>
      </Layout>
    )
  }
}


export default (props) => {
  const data = useStaticQuery(graphql`
    query ThankYou {
      site {
        siteMetadata {
          certapetInfo {
            url {
              ctaUrl
            }
          }
        }
      } 
      icoEmail: file(relativePath: { eq: "page-icon/ico-email.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, maxHeight: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      } 
    } 
  `)
  return (
    <ThankYouPage  {...props} data={data}/>
  )
};
